<template>
  <div id="app">
    <settings v-if="site && !loading" />
    <div
      class="wrapper"
      :class="{ off: !$store.state.settings }"
      :style="{ top: $store.state.settingsHeight + 'px' }"
    >
      <navigation />
      <router-view />
    </div>
  </div>
</template>

<script>
const Settings = () =>
  import(/* webpackChunkName: "Settings" */ "@/components/Settings");
const Navigation = () =>
  import(/* webpackChunkName: "Navigation" */ "@/components/Navigation");

export default {
  components: {
    Settings,
    Navigation,
  },
  data() {
    return {
      loading: true,
    };
  },
  beforeCreate() {
    this.$store.dispatch("loadContent").then(() => {
      this.loading = false;
    });
  },
  computed: {
    site() {
      return this.$store.state.site;
    },
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("resize", this.emitSize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.emitSize);
  },
  methods: {
    emitSize() {
      this.$root.$emit("window-resized");
    },
  },
};
</script>

<style lang="scss">
@import "node_modules/reset-css/sass/_reset";
* {
  box-sizing: border-box;
}
@font-face {
  font-family: "Goldman";
  src: url("./assets/GoldmanSans_W_Rg.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "GoldmanItalic";
  src: url("./assets/GoldmanSans_W_It.woff2") format("woff2");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

::selection {
  background-color: rgb(0, 0, 0);
  color: white;
}

a,
a:active,
a:hover,
a:visited {
  color: black;
  text-decoration: none;
  transition: color 0.5s;
  &:hover {
    color: #aaa;
  }
}

body,
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  --fs: 1.3vw;
  --lh: calc(1.25 * 1.3vw);
  @media screen and (max-width: 1024px) {
    --fs: 2vw;
    --lh: calc(1.25 * 2vw);
  }
  @media screen and (max-width: 1024px) and (orientation: landscape) {
    --fs: 2vw;
    --lh: calc(1.25 * 2vw);
  }
  @media screen and (max-width: 768px) and (orientation: portrait) {
    --fs: 4vw;
    --lh: calc(1.25 * 4vw);
  }
  font-size: var(--fs);
  line-height: var(--lh);
}
#app {
  font-family: "Goldman", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--fs);
  line-height: var(--lh);
  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    transition: top 0.5s;
    &.off {
      transition: top 0.5s;
      top: 0 !important;
    }
  }
  main,
  nav {
    background: white;
  }
  main {
    min-height: 50%;
    padding: calc(2 * var(--lh));
  }
  li,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .title {
    font-family: "GoldmanItalic", sans-serif;
    font-style: normal;
  }
  .italic,
  em {
    font-family: "GoldmanItalic", sans-serif;
    font-style: normal;
  }
  strong {
    font-weight: 700;
  }
  .smallcaps {
    font-feature-settings: "c2sc", "smcp";
    font-variant: all-small-caps;
    // text-transform: uppercase;
    text-transform: lowercase;
  }
  img {
    transition: opacity 0.25s;
  }
  img[lazy="loading"] {
    opacity: 0;
  }
  img[lazy="loaded"] {
    opacity: 1;
  }
}
</style>
