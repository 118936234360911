import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueAxios from "vue-axios";
import auth from "./auth.js";

Vue.use(Vuex);
Vue.use(VueAxios, axios);

export default new Vuex.Store({
  state: {
    site: [],
    pages: [],
    projecttypes: [],
    abc: [],
    year: [],
    settings: false,
    settingsHeight: 0,
    playStatus: false,
    sort: "year",
    current: true,
    info: {
      bio: true,
      contact: true,
      newsletter: true,
      news: true,
      press: true,
      impressum: false,
      solo_exhibitions: true,
      group_exhibitions: true,
      performances: true,
      filmography: true,
      bibliography: true,
      awards: true,
      residencies: true,
      collectives: true,
      teaching: true,
      writing: true,
      other: true,
    },
    categories: {
      solo: true,
      group: true,
      performance: true,
      writing: true,
      talks: true,
      related: true,
    },
    types: {
      images: true,
      video: true,
      audio: true,
      documents: true,
      texts: true,
    },
  },
  getters: {
    getPagesByType: (state, getters) => (type, collection = state.pages) => {
      let results = [];
      if (!collection || !collection.length) {
        return results;
      }
      for (let i = 0; i < collection.length; i++) {
        if (collection[i].type === type) {
          results.push(collection[i]);
        }
        let targetPages = getters.getPagesByType(type, collection[i].children);
        results = results.concat(targetPages);
      }
      return results;
    },
    getPageByUID: (state, getters) => (uid, collection = state.pages) => {
      let i = collection.length;
      while (i--) {
        if (collection[i].uid === uid) {
          return collection[i];
        }
        let targetPage = getters.getPageByUID(uid, collection[i].children);
        if (targetPage) {
          return targetPage;
        }
      }
    },
  },
  actions: {
    async loadContent({ commit }) {
      await axios
        .get("/api/data", {
          withCredentials: true,
          auth: {
            username: auth.u,
            password: auth.p,
          },
        })
        .then(function (response) {
          commit("SET_SITE", response.data);
        });
    },
    async loadAbout({ commit }) {
      await axios
        .get("/api/pages", {
          withCredentials: true,
          auth: { username: auth.u, password: auth.p },
        })
        .then(function (response) {
          commit("SET_PAGES", response.data);
        });
    },
    async loadYears({ commit }) {
      await axios
        .get("/api/years", {
          withCredentials: true,
          auth: { username: auth.u, password: auth.p },
        })
        .then(function (response) {
          commit("SET_YEAR", response.data);
        });
    },
  },
  mutations: {
    SET_SITE(state, site) {
      state.site = site;
    },
    SET_PAGES(state, pages) {
      state.pages = pages;
    },
    SET_YEAR(state, pages) {
      const keysSorted = Object.keys(pages).sort(function (a, b) {
        return b - a;
      });
      const arr = [];
      for (let i = 0; i < keysSorted.length; i++) {
        const obj = {};
        obj.key = keysSorted[i];
        obj.projects = pages[keysSorted[i]];
        arr.push(obj);
      }
      state.year = arr;
    },
  },
});
