import Vue from "vue";
import Router from "vue-router";
import store from "./store.js";
const Home = () => import(/* webpackChunkName: "Home" */ "./views/Home.vue");
const Project = () =>
  import(/* webpackChunkName: "Project" */ "./views/Project.vue");
const Info = () => import(/* webpackChunkName: "Info" */ "./views/About.vue");

Vue.use(Router);

export default new Router({
  mode: "history",
  // scrollBehavior() {
  // return { x: 0, y: 0 };
  // },
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      beforeEnter: (to, from, next) => {
        if (store.state.year.length === 0) {
          store.dispatch("loadYears").then(next);
        } else {
          next();
        }
      },
      children: [
        {
          name: "project",
          path: "projects/:id",
          component: Project,
        },
      ],
    },
    {
      path: "/info",
      name: "info",
      component: Info,
      beforeEnter: (to, from, next) => {
        if (store.state.pages.length === 0) {
          store.dispatch("loadAbout").then(next);
        } else {
          next();
        }
      },
    },
    {
      path: "*",
      redirect: "/",
    },
  ],
});
